import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["toggle", "dropdown", "dropdownIcon"];

  connect() {
    console.log("connected dropdown")  
  }

  toggle(e) {
    e.preventDefault()


    console.log("toggle")

    // if (this.dropdownTarget.classList.contains('hidden')) {
    //   document.querySelectorAll("[data-dropdown-target=dropdown]").forEach((el) => el.classList.add('hidden'));
    // }

    this.dropdownTarget.classList.toggle("hidden");
    console.log(this.dropdownTarget.classList);

    if (this.hasDropdownIconTarget) {
      this.dropdownIconTarget.style.rotate = this.dropdownTarget.classList.contains("hidden") ? "0deg" : "180deg";
    }

  }

}