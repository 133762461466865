import { Controller } from "stimulus";
// import FlexMasonry from "flexmasonry"


export default class extends Controller {

  connect() {

    console.log("OK")

    if (window.location.toString().includes("?back=")) {
    
        document.querySelectorAll(".review-item").forEach((el) => el.classList.add("pointer-events-none"));
        
        setTimeout(() => {
          document.querySelectorAll(".review-item").forEach((el) => el.classList.remove("pointer-events-none"));
        }, 700);
    
    }
    

    // window.onload = function() {
      
    //   // document.querySelector(".reviews-grid").classList.add("invisible");
      
    //   setTimeout(() => {
    //     // document.querySelector(".reviews-grid").classList.remove("invisible");
    //     // document.querySelector(".reviews-grid").classList.add("fade-in-image");
    //   }, 400);
    // }

    // var isNavigatingBack = false;

    // document.addEventListener("turbo:before-visit", async (event) => { 
    //   console.log(event.detail.url);
  

    //   if (isNavigatingBack) {
    //     document.querySelector(".reviews-grid").classList.remove("invisible");
    //   }
     
 
    // })




      // if (!!window.msnry) {
      //   setTimeout(() => {
      //     window.msnry.refreshAll();
      //   }, 200);
      // }

        // window.msnry = FlexMasonry;
        // FlexMasonry.init(".reviews-grid", {
        //   responsive: true,
        //   breakpointCols: {
        //     'min-width: 1500px': 4,
        //     'min-width: 1200px': 4,
        //     'min-width: 992px': 4,
        //     'min-width: 768px': 3,
        //     'min-width: 576px': 2,
        //     'max-width: 575px': 3,
        //   },
        //   numCols: 4,
        // });
      


      // this.element.classList.remove("animate__slideOutRight");
      document.querySelectorAll(".review-item").forEach((el) => el.classList.remove("animate__zoomOut"))



    // document.addEventListener("turbo:load", this.animateIn());

    // document.addEventListener("turbo:before-fetch-request", this.animateOut());


    // document.addEventListener("turbo:before-cache", this.removeAnimations());

  }


  slideOut(e) {
    e.target.closest(".review-item").classList.add("animate__animated", "animate__zoomOut");
    // this.element.classList.remove("animate__animated", "animate__fadeIn");
    // this.element.classList.remove("animate__animated", "animate__slideInRight");
    // this.element.classList.add("animate__animated", "animate__slideOutRight");

  }



  animateIn() {
    document.querySelector("#reviews-title").classList.add("fade-in");
  }

  animateOut() {
    // document.querySelector("#reviews-title").classList.remove("fade-in");
  }

  removeAnimations() {
    // setTimeout(() => {
    //   document.querySelector("#reviews-title").classList.remove("fade-in");
    // }, 400);
  }


  // animateOut() {
  //   this.runAnimations('data-animate-out');
    
  //   // Fade in our loader
  //   transitionTimer = setTimeout(() => {
  //     document.querySelector('.loader').classList.remove('loader--hide');
  //   }, 500);
  // }

  // animateIn() {
  //   runAnimations('data-animate-in');
  //   // Remove animation classes once they've finished
  //   setTimeout(this.removeAnimations, 1000);
  // }

  // runAnimations(dataAttr) {
  //   document.querySelector(`[${dataAttr}]`).foreach((ind, el) => {
  //     el.classList.add(el.dataset[dataAttr]);
  //   });
  // }


  // removeAnimations() {
  //   // Clear the timeout for the loader
  //   window.clearTimeout(transitionTimer);
  //   // Clear up class names before caching
  //   const els = document.querySelectorAll('[data-animate-out], [class*=animate-]');
  //   removeAllAnimateClasses(els);
  // }


  // removeAllAnimateClasses(els) {

  //   els.foreach((el) => {

  //   });

  //   $(els).removeClass(function(index, className) {
  //     return (className.match(/(^|\s)animate-\S+/g) || []).join(' ');
  //   });
  // }
  



}