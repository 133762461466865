import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("OK");



    
      this.element.querySelector(".like-diary-page").addEventListener("click", (e) => {
        var el = this.element.querySelector(".like-diary-page");
        console.log("OK");
        var likesCount = el.querySelector(".likes-count");
        likesCount.innerText == "1" ? likesCount.innerText = "0" : likesCount.innerText = "1";
      });
 
  
  
  
      this.element.querySelector(".dislike-diary-page").addEventListener("click", (e) => {
        var el = this.element.querySelector(".dislike-diary-page");
        console.log("OK");
        var likesCount = el.querySelector(".dislikes-count");
        likesCount.innerText == "1" ? likesCount.innerText = "0" : likesCount.innerText = "1";
      });
 
    
 


  }

}