import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
// import Dragula from 'dragula'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["list"]



  connect () {
         
    var onCoursePage = document.querySelector("body.courses.show") != null;
    this.sortable = Sortable.create(this.element, {
      group: "articles",
      draggable: ".article-card",
      filter: ".fixed-article",
      onMove: function(evt) {
        var isDraggable = document.querySelector(".steps-list.draggable") != null;
        if (onCoursePage && !isDraggable) return false;
      },
      onEnd: function(evt) {
        var isDraggable = document.querySelector(".steps-list.draggable") != null;
        if (onCoursePage && !isDraggable) return false;
        console.log("the event")
        console.log(evt)
        var evento = evt
        console.log("evento", evento)
        let itemEl = evt.item;
        let itemNewIndex = evt.newIndex;
        console.log("item", itemEl);
        console.log("newindex", itemNewIndex)
        console.log("item sortable update url", itemEl.dataset.sortableUpdateUrl)
        if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return
        console.log("onEnd triggered")
        
        var newCourseSection;
        var destinationVolume;
        
        console.log("evt.to", evt.to.dataset.bookVolume);

        if (onCoursePage) {
          newCourseSection = evt.to.dataset.courseSection;
          destinationVolume = evt.to.dataset.bookVolume;
        }

        // const param = "position"

        // const data = new FormData()
        // data.append(param, itemNewIndex + 1)
        // console.log("onend 2")
        
        // Rails.ajax({
        //   url: itemEl.dataset.sortableUpdateUrl,
        //   type: 'PATCH',
        //   data
        // })

        var params = {
          step: evt.to.dataset.step,
          position: itemNewIndex+1,
        }

        if (!!destinationVolume) {
          params["destination_volume"] = destinationVolume;
        }

        if (onCoursePage && newCourseSection != undefined) params["course_section"] = newCourseSection;

        // document.querySelector("#loading_spinner").classList.remove("hidden")

         fetch(itemEl.dataset.sortableUpdateUrl, {
          method: 'PATCH',
          credentials: 'same-origin',
          headers: {
            'Accept': 'text/vnd.turbo-stream.html',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
            body: JSON.stringify(params)
          }).then(response => {
            if (response.ok) {
              // document.querySelector("#loading_spinner").classList.add("hidden");
              // console.log("position: updated")
            }
          })


      }
    })
  }

  end(event) {
    console.log(event);
    console.log("arg0", arguments[0])
    let args = [].slice.apply(arguments)[0];
    let item = args.item;
    let newIndex = args.newIndex;

    if (!item.dataset.sortableUpdateUrl || !window._rails_loaded) return
    console.log("onEnd triggered")

    const param = "position"

    const data = new FormData()
    data.append(param, newIndex + 1)
    console.log("onend 2")
    Rails.ajax({
      url: item.dataset.sortableUpdateUrl,
      type: 'PATCH',
      data
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

}