import { Controller } from "stimulus"
import lightGallery from "lightgallery"

export default class Lightbox extends Controller {
  
  static values = {
    options: Object,
  }

  connect() {
    this.lightGallery = lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    })

    this.element.addEventListener("lgAfterClose", () => {
      this.element.querySelector("img.border-4").classList.remove("border-4")
    });
  }

  disconnect() {
    this.lightGallery.destroy()
  }

  selectImage(e) {
    e.target.classList.add("border-4", "border-green-300")
    console.log("Test");
  }

  get defaultOptions() {
    return {
      download: false,
    }
  }
}