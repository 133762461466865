import { Controller } from "stimulus";
import Rails from 'rails-ujs'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ["list"]

  connect() {
    this.initSortable()
  }

  initSortable() {
    this.sortable = Sortable.create(this.element, {
      group: "diary-pages",
      animation: 300,
      ghostClass: "bg-indigo-200",
      handle: ".diary-page-sortable-handle",
  
    
      onMove: function(evt) {
        
      },
      
      onEnd: function(evt) {
        let itemEl = evt.item;
        let itemNewIndex = evt.newIndex;
        

        if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return
        
        var params = {
          diary_page: {
            position: itemNewIndex+1,
          },
        }


        fetch(itemEl.dataset.sortableUpdateUrl, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken(),
        },
          body: JSON.stringify(params)
        }).then(response => {
          if (response.ok) {
            console.log("position: updated")
          }
        })


      }

    });
  }

}