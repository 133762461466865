import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["grid"];


  toggle(e) {
    
    if (this.element.style.height == "400px") {
      this.element.style.height = "min-content";
    } else {
      this.element.style.height = "400px";
    }

    this.gridTarget.classList.toggle("hidden");
  }


}