import { Controller } from "stimulus"

import UploadAdapter from './upload_adapter';



// function AddPageInfoStyle( editor ) {
//     // Both the data and the editing pipelines are affected by this conversion.
//     editor.conversion.for( 'downcast' ).add( dispatcher => {
//         // Links are represented in the model as a "linkHref" attribute.
//         // Use the "low" listener priority to apply the changes after the link feature.
//         dispatcher.on( 'attribute:linkHref', ( evt, data, conversionApi ) => {
//             const viewWriter = conversionApi.writer;
//             const viewSelection = viewWriter.document.selection;

//             // Adding a new CSS class is done by wrapping all link ranges and selection
//             // in a new attribute element with the "target" attribute.
//             const viewElement = viewWriter.createAttributeElement( 'a', {
//                     target: '_blank'
//                 }, {
//                     priority: 5
//                 } );

//             if ( data.attributeNewValue.match( /ckeditor\.com/ ) ) {
//                 viewWriter.unwrap( conversionApi.mapper.toViewRange( data.range ), viewElement );
//             } else {
//                 if ( data.item.is( 'selection' ) ) {
//                     viewWriter.wrap( viewSelection.getFirstRange(), viewElement );
//                 } else {
//                     viewWriter.wrap( conversionApi.mapper.toViewRange( data.range ), viewElement );
//                 }
//             }
//         }, { priority: 'low' } );
//     } );
// }

function CustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter( loader, editor );
    };
}

// function ConvertParagraphAttributes( editor ) {

//     editor.model.schema.addAttributeCheck( context => {
//         if ( context.endsWith( 'p' ) ) {
//             return true;
//         }
//     } );

//     editor.conversion.for( 'upcast' ).elementToElement( {
//         view: 'paragraph',
//         model: ( viewElement, { writer: modelWriter } ) => {
//             return modelWriter.createElement( 'paragraph', viewElement.getAttributes() );
//         }
//     } );

//     editor.conversion.for( 'downcast' ).elementToElement( {
//         model: 'paragraph',
//         view: 'paragraph'
//     } );


//     editor.conversion.for( 'downcast' ).add( dispatcher => {
//         dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {

//             if ( data.item.name != 'paragraph' ) {
//                 return;
//             }

//             const viewWriter = conversionApi.writer;
//             const viewP = conversionApi.mapper.toViewElement( data.item );

//             // In the model-to-view conversion we convert changes.
//             // An attribute can be added or removed or changed.
//             // The below code handles all 3 cases.
//             if ( data.attributeNewValue ) {
//                 viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewP );
//             } else {
//                 viewWriter.removeAttribute( data.attributeKey, viewP );
//             }
//         } );
//     } );
// }

function ConvertDivAttributes( editor ) {
				// Allow <div> elements in the model.
				editor.model.schema.register( 'div', {
						allowWhere: '$block',
						allowContentOf: '$root'
				} );

				// Allow <div> elements in the model to have all attributes.
				editor.model.schema.addAttributeCheck( context => {
						if ( context.endsWith( 'div' ) ) {
								return true;
						}
				} );

				// The view-to-model converter converting a view <div> with all its attributes to the model.
				editor.conversion.for( 'upcast' ).elementToElement( {
						view: 'div',
						model: ( viewElement, { writer: modelWriter } ) => {
								return modelWriter.createElement( 'div', viewElement.getAttributes() );
						}
				} );

				// The model-to-view converter for the <div> element (attributes are converted separately).
				editor.conversion.for( 'downcast' ).elementToElement( {
						model: 'div',
						view: 'div'
				} );

				// The model-to-view converter for <div> attributes.
				// Note that a lower-level, event-based API is used here.
				editor.conversion.for( 'downcast' ).add( dispatcher => {
						dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
								// Convert <div> attributes only.
								if ( data.item.name != 'div' ) {
										return;
								}

								const viewWriter = conversionApi.writer;
								const viewDiv = conversionApi.mapper.toViewElement( data.item );

								// In the model-to-view conversion we convert changes.
								// An attribute can be added or removed or changed.
								// The below code handles all 3 cases.
								if ( data.attributeNewValue ) {
										viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewDiv );
								} else {
										viewWriter.removeAttribute( data.attributeKey, viewDiv );
								}
						} );
				} );
		}




	function ConvertAnchorAttributes( editor ) {



			// Allow <a> elements in the model to have all attributes.
			editor.model.schema.addAttributeCheck( context => {
					if ( context.endsWith( 'a' ) ) {
							return true;
					}
			} );

		
	}

export default class extends Controller {

	static targets = ["editor"]

 connect(){

  var watchdog = new CKSource.EditorWatchdog();
			window.watchdog = watchdog;

			watchdog.setCreator( ( element, config ) => {
				return CKSource.Editor
					.create( element, config )
					.then( editor => {
						window.editor = editor;



						return editor;
					} )
			} );

			watchdog.setDestructor( editor => {
				return editor.destroy();
			} );
			
			watchdog.on( 'error', handleError );
			

      if (document.querySelector(".ck-editor") == null) {

        		watchdog
				.create( this.element, {
			
            extraPlugins: [ CustomUploadAdapterPlugin, ConvertDivAttributes ],
            customUpload: {
              directUploadUrl: this.element.dataset.directUploadUrl,
              blobUrlTemplate: this.element.dataset.blobUrlTemplate
						},

				toolbar: {
					items: [
						'heading',
						'|',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'alignment',
						'fontSize',
						'fontFamily',
						'fontColor',
						'fontBackgroundColor',
						'bulletedList',
						'numberedList',
						'removeFormat',
						'horizontalLine',
						'|',
						'outdent',
						'indent',
						'|',
						'link',
						'imageUpload',
						'blockQuote',
						'insertTable',
						'findAndReplace',
						'|',
						'undo',
						'redo',
						'imageInsert',
						'todoList',
						'highlight',
						'specialCharacters',
						'htmlEmbed',
						'sourceEditing',
						'textPartLanguage'
					]
				},
				language: 'it',
        link: {
          addTargetToExternalLinks: true,          
        
        },
				image: {
					toolbar: [
						'imageStyle:inline',
						'imageStyle:block',
						'imageStyle:side',
						'linkImage',
						'toggleImageCaption',
					]
				},
				table: {
					contentToolbar: [
						'tableColumn',
						'tableRow',
						'mergeTableCells',
						'tableCellProperties',
						'tableProperties'
					]
				},
		
					licenseKey: '',
					
					
					
				} )
				.catch( handleError );

      }
			
	
			
			function handleError( error ) {
				console.error( 'Oops, something went wrong!' );
				console.warn( 'Build id: ls5tuf113001-x3u550otkmj9' );
				console.error( error );
			}
   }

	 openUploadModal() {
		 document.querySelectorAll("#upload_modal").classList.remove("hidden")
	 }
}