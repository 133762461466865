import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
// import Dragula from 'dragula'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["list"]



  connect () {

    console.log("OK2")

    // document.addEventListener("dragstart", function(e) {
    //   if (e.target.classList.contains("course-section-column")) {
    //     document.querySelectorAll(".article-li").forEach((el) => {
    //       el.draggable = false;
    //     });
    //   } else {
    //     document.querySelectorAll(".article-li").forEach((el) => {
    //       el.draggable = true;
    //     });
    //   }
    // })


    document.querySelectorAll(".article-cards-list").forEach((el) => {
      this.sortable = Sortable.create(el, {
        group: "articles",
        draggable: ".article-li",
        filter: ".fixed-article",
        
        
        onMove: function(e) {
         console.log("on move", e);
        },
        onEnd: function(evt) {
          
        
          console.log("the event")
          console.log(evt)
          var evento = evt
          console.log("evento", evento)
          let itemEl = evt.item;
          let itemNewIndex = evt.newIndex;
          console.log("item", itemEl);
          console.log("newindex", itemNewIndex)
          console.log("item sortable update url", itemEl.dataset.sortableUpdateUrl)
          if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return
          console.log("onEnd triggered")
          
          var newCourseSection;
          var destinationVolume;
          
          console.log("evt.to", evt.to.dataset.bookVolume);
  
     
          newCourseSection = evt.to.dataset.courseSection;
          destinationVolume = evt.to.dataset.bookVolume;
       
  
          // const param = "position"
  
          // const data = new FormData()
          // data.append(param, itemNewIndex + 1)
          // console.log("onend 2")
          
          // Rails.ajax({
          //   url: itemEl.dataset.sortableUpdateUrl,
          //   type: 'PATCH',
          //   data
          // })
  
          var params = {
            step: evt.to.dataset.step,
            position: itemNewIndex+1,
          }
  
          if (!!destinationVolume) {
            params["destination_volume"] = destinationVolume;
          }
  
          if (newCourseSection != undefined) params["course_section"] = newCourseSection;
  
          // document.querySelector("#loading_spinner").classList.remove("hidden")
  
           fetch(itemEl.dataset.sortableUpdateUrl, {
            method: 'PATCH',
            credentials: 'same-origin',
            headers: {
              'Accept': 'text/vnd.turbo-stream.html',
              'Content-Type': 'application/json',
              'X-CSRF-Token': Rails.csrfToken(),
            },
              body: JSON.stringify(params)
            }).then(response => {
              if (response.ok) {
                // document.querySelector("#loading_spinner").classList.add("hidden");
                // console.log("position: updated")
              }
            })
  
  
        }
      })
      
    });
    
 

    
  }

  end(event) {
    console.log(event);
    console.log("arg0", arguments[0])
    let args = [].slice.apply(arguments)[0];
    let item = args.item;
    let newIndex = args.newIndex;

    if (!item.dataset.sortableUpdateUrl || !window._rails_loaded) return
    console.log("onEnd triggered")

    const param = "position"

    const data = new FormData()
    data.append(param, newIndex + 1)
    console.log("onend 2")
    Rails.ajax({
      url: item.dataset.sortableUpdateUrl,
      type: 'PATCH',
      data
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

}