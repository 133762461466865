import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["pages", "currentPageNumber"];

  connect() {

  }


  prevPage() {
    var totalPages = Number(this.element.dataset.totalPages);
    var currentPage = this.pagesTarget.querySelector("img:not(.hidden)")
    var currentPageNumber = Number(currentPage.dataset.page);
    
    if (currentPageNumber-1 >= 1) {
      currentPage.classList.add("hidden");
      currentPage.classList.add("animate__fadeOut")
      
      var prevPage = this.pagesTarget.querySelector(`img[data-page="${currentPageNumber-1}"]`)
      prevPage.classList.remove("hidden");
      prevPage.classList.remove("animate__backInLeft");
      prevPage.classList.remove("animate__slideInRight");
      prevPage.classList.add("animate__fadeOut")
      prevPage.classList.add("animate__faster")
      prevPage.classList.add("animate__slideInLeft")
      this.currentPageNumberTarget.innerText = currentPageNumber-1;
    }
  }



  nextPage() {
    var totalPages = Number(this.element.dataset.totalPages);
    var currentPage = this.pagesTarget.querySelector("img:not(.hidden)")
    var currentPageNumber = Number(currentPage.dataset.page);
    
    if (currentPageNumber+1 <= totalPages) {
      currentPage.classList.add("hidden");
      currentPage.classList.add("animate__fadeOut")

      var nextPage = this.pagesTarget.querySelector(`img[data-page="${currentPageNumber+1}"]`);
      nextPage.classList.remove("hidden");
      nextPage.classList.remove("animate__backInLeft");
      nextPage.classList.add("animate__slideInLeft")
      nextPage.classList.add("animate__faster")
      nextPage.classList.add("animate__slideInRight")
      this.currentPageNumberTarget.innerText = currentPageNumber+1;
    }
  }


}
