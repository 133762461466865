import { Controller } from "stimulus";

export default class Dialog extends Controller {

  static targets = ["dialog"];

  initialize() {
    this.forceClose = this.forceClose.bind(this);
  }

  connect() {
    
    if (this.openValue) {
      this.open();
    }

    document.addEventListener("turbo:before-render", this.forceClose);
  }

  disconnect() {
    document.removeEventListener("turbo:before-render", this.forceClose);
  }

  open(e) {
    e.preventDefault();
    this.dialogTarget.showModal();
  }

  close() {
    this.dialogTarget.setAttribute("closing", "");

    Promise.all(Array.from(this.dialogTarget.getAnimations()).map((animation) => animation.finished)).then(() => {
      this.dialogTarget.removeAttribute("closing");
      this.dialogTarget.close();
    });
  }

  backdropClose(event) {
    if (event.target === this.dialogTarget) {
      this.close();
    }
  }

  forceClose() {
    this.dialogTarget.close();
  }
}

