import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    
    const event = new CustomEvent("loaded-comments-list")
    window.dispatchEvent(event)
  }




}