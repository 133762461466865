import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["settingsIcon", "settingsMenu", "closeSettingsBtn", "toggleNavbarBtn", "toggleNavbarBtnIcon"];

  connect() {

    document.addEventListener("click", function(e) {
      if (!e.target.closest("nav[data-controller='navbar']")) {
        document.querySelectorAll("[data-dropdown-target=dropdown]").forEach((el) => el.classList.add('hidden'));
      }
    });
    

    document.addEventListener("scroll", function(e) {
      
        document.querySelectorAll("[data-dropdown-target=dropdown]").forEach((el) => el.classList.add('hidden'));
      
    });
    


  }


  toggleNavbar(e) {
    
    this.toggleNavbarBtnTarget.classList.toggle("top-14")
    this.toggleNavbarBtnTarget.classList.toggle("top-80")
    this.element.classList.toggle("h-14")
    this.element.classList.toggle("h-80")
    this.toggleNavbarBtnIconTarget.classList.toggle("rotate-180")
    document.querySelectorAll("[data-dropdown-target=dropdown]").forEach((el) => el.classList.add('hidden'));
  }


  toggleSettingsMenu(e) {

    
    // console.log("test");
    console.log(e.target, this.closeSettingsBtnTarget);
    // if (e.target != this.element) return;

    if (this.settingsMenuTarget.classList.contains("hidden")) {
      
      if (!!document.querySelector(".mascotte-container")) {
        document.querySelector(".mascotte-container").style.zIndex = "9";
      }

      this.settingsMenuTarget.classList.remove("hidden");
    } else {
      if (e.target == this.settingsMenuTarget || e.target == this.closeSettingsBtnTarget) {
        e.stopPropagation();

        if (!!document.querySelector(".mascotte-container")) {
          document.querySelector(".mascotte-container").style.zIndex = "99999999";
        }
        this.settingsMenuTarget.classList.add("hidden");
      }
    }
    

   
  }

}