import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["spinner", "slider", "input"]

  connect() {

    var placeholderText = "Quanti sono i chakra?"
    var placeholderCount = 0;

    document.querySelectorAll("input[name='search_option']").forEach((input) => {
      input.addEventListener("click", (e) => {
        if (input.value == "title") {
          this.sliderTarget.value = 0.3;
        } else {
          this.sliderTarget.value = 0.5;
        }
      });
    });


  }



  toggleSpinner(e) {
    var termExceptions = ["CTM", "C.T.M.", "EL", "E-L", "E.L."];

    if (this.inputTarget.value.length > 3 || termExceptions.includes(this.inputTarget.value)) {
      this.spinnerTarget.classList.toggle("hidden");
    } else {
      e.preventDefault();
    }
  }



  increaseWeight() {
    var currentVal = parseFloat(this.sliderTarget.value);
    this.sliderTarget.value = currentVal + 0.01;

  }

  decreaseWeight() {
    var currentVal = parseFloat(this.sliderTarget.value);
    this.sliderTarget.value = currentVal - 0.01;
  }

}
