import { Controller } from "stimulus";
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import customEmojiData from "../new-custom-emoji-list.json"


export default class extends Controller {
  
  static targets = ["emojiPickerContainer", "commentInput"];

  
  connect() {
    console.log(customEmojiData);
  //   var customCategory = { categoryIcons: {
  //     activity: {
  //       svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M57.89 397.2c-6.262-8.616-16.02-13.19-25.92-13.19c-23.33 0-31.98 20.68-31.98 32.03c0 6.522 1.987 13.1 6.115 18.78l46.52 64C58.89 507.4 68.64 512 78.55 512c23.29 0 31.97-20.66 31.97-32.03c0-6.522-1.988-13.1-6.115-18.78L57.89 397.2zM496.1 352c-44.13 0-79.72 35.75-79.72 80s35.59 80 79.72 80s79.91-35.75 79.91-80S540.2 352 496.1 352zM640 99.38c0-13.61-4.133-27.34-12.72-39.2l-23.63-32.5c-13.44-18.5-33.77-27.68-54.12-27.68c-13.89 0-27.79 4.281-39.51 12.8L307.8 159.7C262.2 192.8 220.4 230.9 183.4 273.4c-24.22 27.88-59.18 63.99-103.5 99.63l56.34 77.52c53.79-35.39 99.15-55.3 127.1-67.27c51.88-22 101.3-49.87 146.9-82.1l202.3-146.7C630.5 140.4 640 120 640 99.38z"/></svg>',
  //     },
  //     people: {
  //       src: './people.png',
  //     },
  //   }
  // }
    // previewPosition: "none", skinTonePosition: "none",

    var i18n = {
      "search": "Cerca un'emoji...",
      "search_no_results_1": "Nessuna emoji",
      "search_no_results_2": "Impossibile trovare l'emoji",
      "pick": "Scegli un'emoji…",
      "add_custom": "Aggiungi emoji personalizzata",
      "categories": {
        "activity": "Attività",
        "custom": "Personalizzate",
        "flags": "Bandiere",
        "foods": "Cibi e bevande",
        "frequent": "Usate di frequente",
        "nature": "Animali e natura",
        "objects": "Oggetti",
        "people": "Faccine e persone",
        "places": "Viaggi e luoghi",
        "search": "Risultati della ricerca",
        "symbols": "Simboli"
      },
      "skins": {
        "choose": "Scegli tono della pelle predefinito",
        "1": "Predefinito",
        "2": "Chiaro",
        "3": "Medio-chiaro",
        "4": "Medio",
        "5": "Medio-scuro",
        "6": "Scuro"
      }
    }

    var picker = new Picker({ data: data, custom: customEmojiData, i18n: i18n, previewPosition: "none", skinTonePosition: "none", categories: ["frequent", "people", "hathor", "nature", "foods", "activity", "places", "objects", "symbols", "flags"], set: "native", icons: "outline", theme: "light", categoryIcons: { "hathor": { src: "/assets/emoji/custom/90x90/emoji0.png" } }, onEmojiSelect: (e) => { this.selectEmoji(e, this) }})

    console.log(picker);

    this.emojiPickerContainerTarget.appendChild(picker);





    document.addEventListener("click", (e) => {
      if (!e.target.closest(".emoji-picker-container")) {
        this.emojiPickerContainerTarget.classList.add("hidden");
      }
    });


  }


  close(e, _context) {
    var that = _context;
    setTimeout(() => {
        if (that.emojiPickerContainerTarget.classList.contains("hidden") == false) {
    
        that.emojiPickerContainerTarget.classList.add("hidden");
        }
      }, 0);
  }


  toggle() {
    if (this.hasEmojiPickerContainerTarget) {
      this.emojiPickerContainerTarget.classList.toggle("hidden");
    }
  }

  selectEmoji(e, _context) {
    console.log("selected emoji", e);
      var that = _context;

      var emoji = e.native == undefined ? e.shortcodes : e.native;

      that.insertAtCursor(that.commentInputTarget, emoji);
      var event = new Event('change');
      this.commentInputTarget.dispatchEvent(event);
  }



  insertAtCursor(myField, myValue) {
    
    if (document.selection) {
        myField.focus();
        const sel = document.selection.createRange();
        sel.text = myValue;
    }
    
    else if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);

        myField.selectionStart = startPos + myValue.length; myField.selectionEnd = startPos + myValue.length;

    } else {
        myField.value += myValue;
    }
}

}

