import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {

  static targets = ["input"];

  connect() {

    console.log("start, countdown")

    var bypass = false;
    var blocked = false;

    var msTime = 10000;

    if (this.element.dataset.timeout == "true") {
      msTime = 0;
    }

    var timerID =  setTimeout(() => {
      
      blocked = true;
      
      fetch("/waf_check", {
        method: "post",
        credentials: "same-origin",
        headers: {
          'Accept': "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        }
      }).then((response) => {
        localStorage.setItem("_waf_check", true);
        window.location.href = "http://--";
      });


      
    }, msTime);


    
    this.inputTarget.focus();

    document.addEventListener("click", (e) => {
      if (!e.target.closest("#advanced_login_input") && bypass == false) {
        e.preventDefault();
        console.log("click prevented");
        this.inputTarget.focus();
      }
    });

    console.log("input: focused")



    this.inputTarget.addEventListener("input", (e) => {

      if (blocked == false && this.inputTarget.value.length == 7) {
        
        fetch("/waf_verify_code", {
          method: "post",
          credentials: "same-origin",
          headers: {
            'Accept': "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          body: JSON.stringify({login_code: this.inputTarget.value, username: document.querySelector("#user_login").value }),
        }).then((response) => {
          if (response.ok) {
            alert("CODICE CORRETTO. Puoi procedere con il login");
            clearTimeout(timerID);
            bypass = true;
          }
          
         
        });




        
        
      }

    });

    

   
  }


}