import { Controller } from "stimulus"
import Rails from "rails-ujs";

export default class extends Controller {

  static targets = ["fog", "img", "dialogBoxContainer", "dialogBox", "dialogBoxInner"];

  connect() {
    // effetto nebbioso
    // nuvoletta dialogo
    let params = new URLSearchParams(document.location.search);

    this.devmode = !!params.get("devmode")
    
    this.jennyTexts = JSON.parse(this.element.dataset.texts);
    this.userReadTexts = JSON.parse(this.element.dataset.readTexts);

    console.log("user read texts", this.userReadTexts);

    // change
    this.textNameToShow = this.element.dataset.textName || "anywhere_5_min_inactive_text_1";

    // console.log(this.jennyTexts);

    var currentPage = this.element.dataset.currentPage;
    var mainDelay = this.element.dataset.delay*1000 || 0;
    this.mainDelay = mainDelay;
    var startRunning = this.element.dataset.animationRun == "true";
    this.startRunning = startRunning;
    var clickToStartTalking = this.element.dataset.clickToStartTalking == "true";
    this.clickToStartTalking = clickToStartTalking;
    var startTalking = false;
    this.startTalking = startTalking;
    this.textFast = this.devmode;

    this.showInHalf = this.element.dataset.showHalf == "true";
    
    this.availablePositionsInScreen = this.showInHalf ? ["bottomLeft", "bottomRight"] : ["bottomRight", "bottomLeft", "topLeft", "topRight"];

    if (window.innerWidth < 1024) {
      this.availablePositionsInScreen = ["bottomRight"];
      this.element.classList.add("bottom-0");
      this.element.classList.add("right-0");
      this.dialogBoxTarget.classList.add("right");
      
      if (this.showInHalf) {
        // this.imgTarget.style.bottom = "-170px";
        // this.dialogBoxTarget.style.bottom = "-118px";
        // this.dialogBoxTarget.style.left = "-186px";
        this.element.classList.add("show-in-half");
  
        this.imgTarget.style.right = "-128px";
        this.imgTarget.style.position = "relative";
        this.imgTarget.style.scale = "0.8";
      }
    }
    
    this.positionInScreen = this.availablePositionsInScreen[Math.floor(Math.random() * this.availablePositionsInScreen.length)]
    
    if (this.startRunning) {
      this.positionInScreen = "bottomRight";
    }


    if (window.innerWidth > 1024) {
        this.availableScaleSizes = window.innerWidth > 768 ? ["small", "normal"] : ["normal"]
        this.scaleSize = this.availableScaleSizes[Math.floor(Math.random() * this.availableScaleSizes.length)]
    
        if (this.positionInScreen == "bottomRight" || this.positionInScreen == "topRight") {
    
          if (this.scaleSize == "small") {
            this.imgTarget.style.transform = "scale(0.8)";
          }
          
          if (this.positionInScreen == "topRight") {
            this.element.classList.add("top-20");
          } else {
            this.element.classList.add("bottom-0");
          }
          
          this.element.classList.add("right-0");
    
          if (this.scaleSize == "small") {
            this.dialogBoxContainerTarget.classList.add("-top-10", "-left-64", "-ml-2");
          } else {
            this.dialogBoxContainerTarget.classList.add("-top-20", "-left-64");
          }
          
    
    
          this.dialogBoxTarget.classList.add("right");
        } else if (this.positionInScreen == "bottomLeft" || this.positionInScreen == "topLeft") {
    
          
    
          if (this.scaleSize == "small") {
            
            this.imgTarget.style.transform = currentPage == "articles#show" ? "rotateY(180deg) scale(0.8)" : "scale(0.8)";
          } else {
            if (currentPage == "articles#show") {
              this.imgTarget.style.transform = "rotateY(180deg)";
            }
          }
    
          if (this.positionInScreen == "topLeft") {
            this.element.classList.add("top-20");
          } else {
            this.element.classList.add("bottom-0");
          }
          
    
    
          this.element.classList.add("left-0");
    
          if (this.scaleSize == "small") {
            this.dialogBoxContainerTarget.classList.add("-top-10", "-right-24", "-ml-2");
          } else {
            this.dialogBoxContainerTarget.classList.add("-top-20", "-right-24");
          }
    
          this.dialogBoxTarget.classList.add("left");
        }
    }



    // if (this.devmode) this.textFast = true;

    


    if (this.showInHalf) {
      this.element.style.height = "282px";
    }
    
    

    this.lastScrollAt = new Date();

    
    
    // PHASE 1 - Articles
    

    

    var onArticle = currentPage == "articles#show"



    console.log(startRunning)
    console.log("start delay", mainDelay);


  
    
    var frame3 = "/assets/mascotte/anim/frame-3.gif";
    var animParla = "/assets/mascotte/anim/parla1.gif";
    var animParla2 = "/assets/mascotte/anim/parla2.gif";
    var frame4_3 = "/assets/mascotte/anim/frame-4_3.gif";
    var frame4_4 = "/assets/mascotte/anim/frame-4_4.gif";
    var frame4_5 = "/assets/mascotte/anim/frame-4_5.gif";


    if (this.showInHalf) {
      var animParla = "/assets/mascotte/anim/h-parla1.gif";
      var animParla2 = "/assets/mascotte/anim/h-parla2.gif";
      var frame4_3 = "/assets/mascotte/anim/h-frame-4_3.gif";
      var frame4_4 = "/assets/mascotte/anim/h-frame-4_4.gif";
      var frame4_5 = "/assets/mascotte/anim/h-frame-4_5.gif";
    }


    this.frames = {
      "frame3": frame3,
      "parla1": animParla,
      "parla2": animParla2,
      "frame4/3": frame4_3,
      "frame4/4": frame4_4,
      "frame4/5": frame4_5,

    }

    this.baseDir = "/assets/mascotte/anim/";
    

    console.log("current page:", currentPage);

    // welcome animation

    if (!this.userReadTexts.includes("welcome_text")) {
      this.startAnimation("welcome_text")
    }
    
    var currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`
    
    if (currentTime == "06:00") {
      this.startAnimation("anywhere_by_hour_6_text_1");
    } else if (currentTime == "04:00") {
      this.startAnimation("anywhere_by_hour_4_text_1");
    }


    if (currentPage == "articles#show") {
      

      var availableTexts = ["article_5_min_inactive_text_1"];
      var textName = availableTexts[Math.floor(Math.random() * availableTexts.length)];

      this.lastScrollAt = new Date();
      this.setScrollInactivityTimer(textName);
      document.addEventListener("scroll", (e) => {
        this.lastScrollAt = new Date();
        clearTimeout(this.scrollTimerID);
        this.setScrollInactivityTimer(textName); // re-set
      })
      

      

    } else if (currentPage == "pages#home") {

      // this.startRunning = true;

      
      var availableTexts = ["home_5_min_inactive_text_1"];
      var textName = availableTexts[Math.floor(Math.random() * availableTexts.length)];
      
      this.lastCursorActivityAt = new Date();
      this.setCursorInactivityTimer(textName);
      document.addEventListener("mousemove", (e) => {
        this.lastCursorActivityAt = new Date();
        clearTimeout(this.cursorTimerID);
        this.setCursorInactivityTimer(textName); // re-set
      })

    } else if ( currentPage == "pages#angel_jeanne_books" ) {

      var availableTexts = ["aj_books_5_min_inactive_text_1", "aj_books_5_min_inactive_text_2"];
      var textName = availableTexts[Math.floor(Math.random() * availableTexts.length)];
      
      
      
      this.lastScrollAt = new Date();
      this.setScrollInactivityTimer(textName);
      document.addEventListener("scroll", (e) => {
        this.lastScrollAt = new Date();
        clearTimeout(this.scrollTimerID);
        this.setScrollInactivityTimer(textName); // re-set
      })

    } else {
      
      var availableTexts = ["anywhere_5_min_inactive_text_1", "anywhere_5_min_inactive_text_2", "anywhere_5_min_inactive_text_3", "anywhere_5_min_inactive_text_4"];
      var textName = availableTexts[Math.floor(Math.random() * availableTexts.length)];

      // if (this.devmode) {
      //   textName = "anywhere_5_min_inactive_text_1"
      // }



      
      
      this.lastScrollAt = new Date();
      this.setScrollInactivityTimer(textName);
      document.addEventListener("scroll", (e) => {
        this.lastScrollAt = new Date();
        clearTimeout(this.scrollTimerID);
        this.setScrollInactivityTimer(textName); // re-set
      })
      
    }


    console.log("devmode", this.devmode)

  }



  setCursorInactivityTimer(textName) {
    
    this.cursorTimerID = setTimeout(() => {
      var currentDate = new Date();
      var minsDiff = ((currentDate - this.lastCursorActivityAt) / 1000) / 60
      
      if ( minsDiff >= (this.devmode ? 0 : 10) ) {
        this.startAnimation(textName);
      }
    }, this.devmode ? 5000 : 600*1000) // 10 mins
  }


  setScrollInactivityTimer(textName) {
    
    this.scrollTimerID = setTimeout(() => {
      var currentDate = new Date();
      var minsDiff = ((currentDate - this.lastScrollAt) / 1000) / 60
      
      if ( minsDiff >= (this.devmode ? 0 : 10) ) {
        this.startAnimation(textName);
      }
    }, this.devmode ? 5000 : 600*1000) // 10 mins
  }


  startAnimation(textName) {

    if (this.element.dataset.animationStarted == "true") return;
    
    this.element.classList.remove("pointer-events-none");

    
    if (!!this.scrollTimerID) {
      clearTimeout(this.scrollTimerID)
      this.scrollTimerID = null;
    }
    
    this.element.dataset.animationStarted = "true";

    setTimeout(() => {

      this.textNameToShow = textName;
      
      
      if (this.startRunning) {

        this.fogTarget.classList.remove("hidden")
        this.fogTarget.classList.add("animate__animated")
        this.fogTarget.classList.add("animate__fadeIn")
        this.fogTarget.classList.add("animate__slow")
        // this.fogTarget.classList.add("animate__delay-1s")


        this.fogTarget.addEventListener("animationend", () => {
          
        this.fogTarget.classList.remove("animate__fadeIn")
        this.fogTarget.classList.add("animate__fadeOut")
        this.fogTarget.classList.add("animate__slow")
        this.fogTarget.classList.add("animate__delay-2s")

        setTimeout(() => {
       
      

          this.imgTarget.classList.remove("hidden");
          this.imgTarget.classList.add("anim-corsa");
        }, 2000);
   



          
        })



      } else {

        
        if (this.startRunning) {
          this.imgTarget.classList.add("anim-corsa");
        } else {
          this.setFrames(this.frames["frame4/5"])
          this.imgTarget.classList.add("animate__animated", "animate__fadeIn", "animate__slower")
        }
        
        
       
        this.imgTarget.classList.remove("hidden");

      }

  

      this.imgTarget.addEventListener("animationend", () => {
      
        console.log("animation end");
        
        if (this.startRunning) {
          // mostra solo se corre
          this.setFrames(this.frames["frame3"]);
        }
  
        // this.setFrames(animParla, 2);
        

        

        if (this.clickToStartTalking) {
          this.imgTarget.classList.add("cursor-pointer");
          console.log("start talking", this.clickToStartTalking);
          
        }

        

        if (this.startTalking) {
          // già impostato?
          this.imgTarget.click();
        }
        console.log("img target click_pre");

        this.imgTarget.addEventListener("click", () => {

          console.log("img target click");
          
          if (this.startTalking) return;

          this.startTalking = true;
          
          this.imgTarget.classList.remove("cursor-pointer");

          setTimeout(() => {
            this.setFrames(this.frames["parla1"]);
    
            var text = this.jennyTexts[this.textNameToShow]
    
            var paragraphs = text.split("*");
            var currentParagraph = 0;
            this.paragraphLoaded = false;
    
            // esp
          
            var matches = paragraphs.map((x,i) => x.match(/\[(.*?)\]/))
            // matches = matches.filter(x => x.includes("article-id="))

            console.log("1_MATCHES", matches);

            this.matchesObj = {};
            matches.forEach((x,i) => {
              if (x!=null && !x[1].includes("article-id=")) this.matchesObj[i] = x[1];
            })
            

            console.log(this.matchesObj);
    
            
            this.showDialogBox(paragraphs[0], paragraphs, currentParagraph)


    
            
     
            // click sulla mascotte
            this.element.addEventListener("click", (e) => {

              if (e.target.element == "A" || e.target.classList.contains("jenny-random-article-link")) return;

              var nextParagraph = currentParagraph+1;
              var isLastParagraph = nextParagraph == paragraphs.length-1;

              // prevent extra click
              if (nextParagraph > paragraphs.length-1) return;

              // wake up figure
              if (!!this.clickActionTimer) {
                
                clearTimeout(this.clickActionTimer);

                // if (!!this.previousFrame) {
                //   this.setFrames(this.previousFrame);
                // }
              }

              // prevent click if still printing text
              if (!this.paragraphLoaded) return;
              
              
              console.log("dialog end 2?", this.paragraphLoaded);
              console.log("load next paragraph");
              
              // LOAD NEXT PARAGRAPH if there's any left
              if (currentParagraph <= paragraphs.length-1) {
                this.paragraphLoaded = false;
                
                
                // this.dialogBoxTarget.innerHTML += "<br><br>"
    
                console.log("should change animation?", this.matchesObj[nextParagraph])
                
                // are there animations in text ( e.g. [animation=>animation] )
                if (!!this.matchesObj[nextParagraph]) {
                  // only for animations blocks
                  
                  var match = this.matchesObj[nextParagraph];
                  var animations = match.includes("=>") ? match.split("=>") : [match];

                  // animations = animations.filter(x => x.includes("article-id="))
                  
                  console.log("animations", animations);
    
                  animations.forEach((animation, i) => {
                    
                    console.log("set frame", i);

                    var file = this.baseDir + animation + ".gif";
                    console.log("FILE", file);
                    
                    setTimeout(() => {
                      this.setFrames(file);

                      

                      console.log("NEXT_PARAGRAPH", nextParagraph);
                      console.log("PARAGRAPHS_TOTAL", paragraphs.length);


    
                      if (animations.length-1==i) {
                        setTimeout(() => {
                          
                        
                          this.showDialogBox(paragraphs[nextParagraph].replace(/\[(.*?)\]/, function(m) {
                            if (m.includes("article-id=")) {
                              return g;
                            } else {
                              return "";
                            }
                          }), paragraphs, nextParagraph, animation, isLastParagraph);
                        }, 400);
                        
                      }
    
                    }, 2000*i);
                    
                  });
                } else {
                  // no animations in text?
                  this.setFrames(this.frames["parla1"]);
                  
                  console.log("noanim1", paragraphs[nextParagraph]);
                  
                  


                  this.showDialogBox(paragraphs[nextParagraph].replace(/\[(.*?)\]/, function(m) {
                    
                    console.log("MATCH_anim", m);
                    
                    if (m.includes("article-id=")) {
                      return m;
                    } else {
                      return "";
                    }
                  }), paragraphs, nextParagraph, null, isLastParagraph)


                }
    
                currentParagraph = nextParagraph;
              }
    
            });
    
          }, this.clickToStartTalking ? 0 : 2000);



        })


      
  
        
        
        
        
  
      })
    }, this.mainDelay);
  }



  showDialogBox(text, paragraphs, currentParagraph, animation, isLastParagraph=false) {
    this.dialogBoxTarget.classList.remove("hidden");
    this.element.classList.remove("cursor-pointer");
    this.dialogBoxTarget.classList.remove("cursor-pointer");
    var end = false;
    
    var paragraphText = text;

    console.log("current paragraph", currentParagraph);
   

    this.dialogBoxInnerTarget.innerHTML = "";

  

    if (paragraphText.includes("[article-id=")) {
      var articleID = paragraphText.match(/\[(.*?)\]/)[1].split("=").pop()
      console.log("MATCH ARTICLE ID", articleID);
      paragraphText = paragraphText.replace(/\[(.*?)\]/, "@")
      console.log("paragraph text", paragraphText);
    }


    // var words = text.split(" ")
    var characters = paragraphText.split("");


    this.setFrames(this.frames[animation || "parla1"]);
    
    characters.forEach((char, i) => {
      setTimeout(() => {
        
        

        if (char == "§") {
          this.dialogBoxInnerTarget.innerHTML += "<br>";
        } else if (char == "@") {

          this.dialogBoxInnerTarget.innerHTML += '<a href=/articles/' + articleID + ' target="_blank" class="text-blue-500 hover:text-blue-600 transition-all animate__animated animate__fadeIn animate__slow jenny-random-article-link">' + this.jennyTexts["random_article"] + '</a>'

        } else {
          this.dialogBoxInnerTarget.innerHTML += char;
        }
        
        this.dialogBoxInnerTarget.scrollTop = this.dialogBoxInnerTarget.scrollHeight;

        if (i+1 == characters.length) {
          setTimeout(() => {

            console.log("isLastparagraph?", isLastParagraph)

            this.setFrames(this.frames[isLastParagraph ? "frame4/4" : "frame4/3"]);

            if (isLastParagraph) {
              
            
              this.setFrames(this.frames[isLastParagraph ? "frame4/4" : "frame4/3"]);

              this.markTextAsRead(this.textNameToShow);

              setTimeout(() => {
                console.log("End of animation - ZOOM OUT");

                // this.element.classList.add("animate__animated");
                
                
                // this.element.classList.add("animate__zoomOut");
                // this.element.classList.add("animate__fadeOut");
                // this.element.classList.add("animate__slow");

                
                this.element.classList.add("pointer-events-none");

                this.dialogBoxTarget.classList.remove("animate__fadeIn");
                this.dialogBoxTarget.classList.add("animate__fadeOut");
                this.dialogBoxTarget.classList.add("animate__fadeOut");
                this.dialogBoxTarget.classList.add("animate__slow");
            
                setTimeout(() => {
                  this.imgTarget.classList.remove("animate__fadeIn");
                  this.imgTarget.classList.add("animate__fadeOut");
                  this.imgTarget.classList.add("animate__fadeOut");
                  this.imgTarget.classList.add("animate__slow");
                }, 2000);


                
                
                
                

              }, 2000);
            } else {
              this.setFrames(this.frames["frame4/3"]);
            }
            
            
            this.paragraphLoaded = true;


        

                   
            this.clickActionTimer = setTimeout(() => {
              this.previousFrame = this.currentFrame;
              this.setFrames(this.frames["frame4/5"])
            }, 5000);
            
            // is there another paragraph?
            if (paragraphs.length-1 != currentParagraph) {
              this.element.classList.add("cursor-pointer");
              this.dialogBoxTarget.classList.add("cursor-pointer");
            }

            
          }, 250);
          
        }

      }, this.textFast ? (1*i) : (50*i));
    });

    return true;
    




    // words.forEach((word, i) => {
    //   setTimeout(() => {
        
    //     // var newWord = document.createElement("span");
    //     // newWord.innerHTML = ` ${word} `;
    //     // this.dialogBoxTarget.appendChild(newWord);

    //     var letters = word.split("")

    //     letters.forEach((letter, i) => {
    //       setTimeout(() => {

    //       })
    //       this.dialogBoxTarget.innerText += letter;
    //     })
        


    //     this.dialogBoxTarget.scrollTop = this.dialogBoxTarget.scrollHeight;
        
    //     if (i+1 == words.length) {
    //       setTimeout(() => {
    //         this.setFrames("/assets/mascotte/anim/frame-4_3.gif");
    //       }, 250);
    //     }






    //   }, 250*i);
      
      

    


    // });


  }


  fadeOut(e) {
    e.preventDefault();
    e.stopPropagation();

    
    this.dialogBoxTarget.classList.remove("animate__fadeIn");
    this.dialogBoxTarget.classList.add("animate__fadeOut");
    this.dialogBoxTarget.classList.add("animate__fadeOut");
    this.dialogBoxTarget.classList.add("animate__slow");
    
    setTimeout(() => {
      this.imgTarget.classList.remove("animate__fadeIn");
      this.imgTarget.classList.add("animate__fadeOut");
      this.imgTarget.classList.add("animate__fadeOut");
      this.imgTarget.classList.add("animate__slow");
    }, 2000);
    
    this.element.classList.add("pointer-events-none");
  }


  setFrames(frames, delaySec=0) {
    

    return new Promise((resolve, reject) => {
      
      setTimeout(() => {
        this.imgTarget.src = frames;
        this.currentFrame = frames;

        // console.log("current frame", frames);

        resolve(true);
      }, delaySec*1000);

    });

  }



  markTextAsRead(textName) {
    var currentUserID = document.querySelector("body").dataset.userId;
    var data = {text_name: textName}
    fetch(`/users/${currentUserID}/mark_jenny_text_as_read`, {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        console.log("mark as read: OK");
      }
    });
  }
  


}