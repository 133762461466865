import { Controller } from 'stimulus'


export default class extends Controller {

  static targets = ["commentsFrame"];
  
  connect() {
    console.log("reviews page")
  }

  toggleCommentsListBtn(e) {
    console.log("toggle list")

    if (!!this.commentsFrameTarget.src) {
      e.preventDefault();
      this.commentsFrameTarget.innerHTML = ""
      this.commentsFrameTarget.src = ""  
    }

    
  }



}