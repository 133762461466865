import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input", "submit"]


  connect() {
    setTimeout(() => {
      document.querySelector("#search-bgvideo").play();
    }, 0);




    document.querySelectorAll(".article-item").forEach((item, i) => {
      setTimeout(() => {
        item.classList.remove("hidden");
        item.classList.add("animate__fadeIn");
      }, 500*i);
    })


    document.addEventListener("click", function(e) {
      if (!e.target.closest(".new-q-modal-inner")) {
        document.querySelector("#new_general_question_modal").src = "";
        document.querySelector("#new_general_question_modal").innerHTML = ""
      }
    });


    

  }


}