import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = [];
  
  connect() {
    var urlParams = new URLSearchParams(window.location.search);


    if (urlParams.has('quick-link-to')) {
      // check current page

      document.querySelectorAll(".step-toggle").forEach((toggle) => {
        
      
        toggle.querySelector("turbo-frame").classList.add("hidden");
      })


      document.querySelectorAll(".step-toggle .chevron").forEach((el) => {
        el.classList.remove("rotate-180");
      });


      document.querySelectorAll(".volume-toggle .chevron").forEach((el) => {
        el.classList.remove("rotate-180");
      });


      document.querySelectorAll(".volume-list").forEach((toggle) => {
        toggle.classList.add("hidden");
        
      })


      setTimeout(() => {
        if (urlParams.has('quick-link-to')) {
          var scrollTarget = document.querySelector(`[data-quick-link-id=${urlParams.get("quick-link-to")}]`);
  
          scrollTarget.scrollIntoView({behavior: "smooth", block : "center"});
          scrollTarget.classList.add("animate__animated", "animate__flash", "animate__repeat-2", "animate__slow")
          scrollTarget.click();
  
        }
  
  
      }, 100);


      
    }


  

  }


}