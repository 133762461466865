import { Controller } from "stimulus";


export default class extends Controller {

  static targets = ["grid"];

  connect() {
    
    document.addEventListener("click", function(e) {

      if (!e.target.closest(".copy_favorite_article_modal")) {

        if (!!document.querySelector(".copy_favorite_article_modal")) {
          // document.querySelector(".copy_media_modal").parentElement.src = "";
          document.querySelector(".copy_favorite_article_modal").classList.add("hidden");
        }
        
      }




      if (!e.target.closest(".copy_note_modal")) {

        if (!!document.querySelector(".copy_note_modal")) {
          // document.querySelector(".copy_media_modal").parentElement.src = "";
          document.querySelector(".copy_note_modal").classList.add("hidden");
        }
        
      }




    });

  }



  toggle(e) {
    
    if (this.element.style.height == "400px") {
      this.element.style.height = "min-content";
    } else {
      this.element.style.height = "400px";
    }

    this.gridTarget.classList.toggle("hidden");
  }

  

}