import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["navTab", "tabView", "passwordFrame"]

  connect() {

    let touchstartX = 0

    const slider = document.querySelector(".profile-nav-tab-grid")

    slider.addEventListener('touchmove', e => {
      touchstartX = e.changedTouches[0].screenX
      
      if (touchstartX) document.querySelector("#swipe_left_animation").classList.add("hidden")
    })


    if (document.querySelector("#user_profile").dataset.clickAction == "openNotes") {
      setTimeout(() => {
        document.querySelector(".open-notes-btn").click();
      }, 0);
    }



    var urlParams = new URLSearchParams(window.location.search);

    if (!!urlParams) {
      var tabToOpen = urlParams.get('open_tab')
      if (!!tabToOpen) {
        document.querySelector(`[data-tab="${tabToOpen}"]`).click();
      }
    }

  }

  animateTab() {
    document.querySelectorAll(".profile-nav-tab-open").forEach(el => {if (el != this.navTabTarget) el.classList.remove("profile-nav-tab-open")})

    if (this.navTabTarget.classList.contains("profile-nav-tab-open")) {
      this.navTabTarget.classList.remove("profile-nav-tab-open")
      document.querySelector(".profile-tab-view").dataset.tabViewOpen = ""

      // info: default tab
      document.querySelector(".profile-tab-view").dataset.tabViewOpen = "info"
      document.querySelector(".profile-nav-tab[data-tab='info']").classList.add('profile-nav-tab-open')
      document.querySelector(".tab-view-content-info").classList.remove("is-hidden")
      document.querySelector(".tab-view-content-dynamic").classList.add("is-hidden")

    } else {
      this.navTabTarget.classList.add("profile-nav-tab-open")
      document.querySelector(".profile-tab-view").dataset.tabViewOpen = this.navTabTarget.dataset.tab;
      if (this.navTabTarget.dataset.tab == "info") {
        document.querySelector(".tab-view-content-info").classList.remove("is-hidden")
        document.querySelector(".tab-view-content-dynamic").classList.add("is-hidden")
      } else {
        document.querySelector(".tab-view-content-info").classList.add("is-hidden")
        document.querySelector(".tab-view-content-dynamic").classList.remove("is-hidden")
        document.querySelector("#user_profile_tab_view_content").removeAttribute("src")
      }

    }
  }

    togglePasswordFrame() {
      if (this.passwordFrameTarget.classList.contains("is-hidden")) {
        this.passwordFrameTarget.removeAttribute("src")
        this.passwordFrameTarget.classList.remove("is-hidden")
      } else {
        this.passwordFrameTarget.classList.add("is-hidden")
      }
    }


}