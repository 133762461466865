import { Controller } from "stimulus";
import Rails from "rails-ujs";


export default class extends Controller {
  static targets = ["chevron", "volumeList"]

  connect() {}

  toggleVolume(e) {
    e.preventDefault()
    this.chevronTarget.classList.toggle("rotate-180")
    this.volumeListTarget.classList.toggle("hidden")
    this.saveVolumesStatus()
  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  saveVolumesStatus() {
    if (!document.body.classList.contains("user-logged-in")) return;

    fetch(`/users/${this.currentUserID}/settings/courses_sections`, {
      method: "put",
      credentials: "same-origin",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(
        {
          course_id: this.volumeListTarget.dataset.course,
          open_sections: Array.from(document.querySelectorAll(".section-list:not(.hidden)")).map(el => el.dataset.section),
        } 
      )
    })
  }

}