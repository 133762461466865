import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'


export default class extends Controller {
  static targets = ["list"]


  connect() {
    
    this.sortable = Sortable.create(this.element, {
      group: "quicklinks",
      animation: 300,
      ghostClass: "bg-indigo-200",
     
      
    
      onMove: function(evt) {
        
      },
      
      onEnd: function(evt) {
        let itemEl = evt.item;
        let itemNewIndex = evt.newIndex;
        

        if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return
        
        var params = {
          quick_link: {
            position: itemNewIndex+1,
          },
        }

     

        fetch(itemEl.dataset.sortableUpdateUrl, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken(),
        },
          body: JSON.stringify(params)
        }).then(response => {
          if (response.ok) {
            console.log("position: updated")
          }
        })


      }

    })
  }






  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

}