import { Controller } from "stimulus";
import Rails from 'rails-ujs'

export default class extends Controller {

  static targets = ["articleCard", 
                    "decrementTextSizeButton", 
                    "incrementTextSizeButton", 
                    "currentTextSizeIndicator", 
                    "toggleThemeCustomizerButton",
                    "themeCustomizer"]

    initialize() {
      this.applyCustomStyles()
      this.articleId = this.articleCardTarget.dataset.articleId;
    }

    toggleDarkTheme() {
      this.resetCustomStyles(false)
      this.articleCardTarget.querySelector("#article_card_content").classList.toggle('dark-theme')
    }

    currentTextSize(textSize) {
        var currentTextSizeIndicator;

        switch (textSize) {
        case "":
          currentTextSizeIndicator = "1"
          break;
        case "1.4rem":
          currentTextSizeIndicator = "5"
          break;
        case "1.3rem":
          currentTextSizeIndicator = "4"
          break;
        case "1.2rem":
          currentTextSizeIndicator = "3"
          break;
        case "1.1rem":
          currentTextSizeIndicator = "2"
          break;
        default:
          currentTextSizeIndicator = "1"
          break;
      }

      return currentTextSizeIndicator;
    }

    decrementTextSize() {
      var currentTextSize = document.querySelector("#article_card_content p").style.fontSize;
      var nextTextSize;
      var currentTextSizeIndicator;

      switch (currentTextSize) {
        case "":
          nextTextSize = "1rem"
          currentTextSizeIndicator = "1"
          break;
        case "1.4rem":
          nextTextSize = "1.3rem"
          currentTextSizeIndicator = "4"
          break;
        case "1.3rem":
          nextTextSize = "1.2rem"
          currentTextSizeIndicator = "3"
          break;
        case "1.2rem":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1.1rem":
          nextTextSize = "1rem"
          currentTextSizeIndicator = "1"
          break;
        default:
          break;
      }

      document.querySelectorAll(".article-content-formatted, #article_card_content p").forEach(el => el.style.fontSize = nextTextSize)
      if (currentTextSizeIndicator != undefined) this.currentTextSizeIndicatorTarget.innerHTML = currentTextSizeIndicator
    }

    incrementTextSize() {
      
      var currentTextSize = document.querySelector("#article_card_content p").style.fontSize;

      var nextTextSize;
      var currentTextSizeIndicator;

      switch (currentTextSize) {
        case "":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1rem":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1.1rem":
          nextTextSize = "1.2rem"
          currentTextSizeIndicator = "3"
          break;
        case "1.2rem":
          nextTextSize = "1.3rem"
          currentTextSizeIndicator = "4"
          break;
        case "1.3rem":
          nextTextSize = "1.4rem"
          currentTextSizeIndicator = "5"
          break;
        default:
          break;
      }

      document.querySelectorAll(".article-content-formatted, #article_card_content p").forEach(el => el.style.fontSize = nextTextSize)
      if (currentTextSizeIndicator != undefined) this.currentTextSizeIndicatorTarget.innerHTML = currentTextSizeIndicator
    }

    toggleThemeCustomizer() {

      
      if (this.themeCustomizerTarget.classList.contains("is-hidden")) {
        this.themeCustomizerTarget.classList.remove("is-hidden")
      } else {
        this.themeCustomizerTarget.classList.add("is-hidden")
      }
    }

    saveCustomStyles() {

      var darkTheme = document.querySelector("#article_card_content").classList.contains("dark-theme")
      var backgroundColor = document.querySelector("#article_card_content").style.backgroundColor
      var textColor =  document.querySelector(".article-content-formatted").style.color
      var textSize = document.querySelector("#article_card_content p").style.fontSize

      // var commentsBackgroundColor = document.querySelector("#comments_list .comment").style.background;
      // var commentsTextColor = document.querySelector("#comments_list .comment").style.color;

      var customStyles = {darkTheme: darkTheme, 
                          backgroundColor: backgroundColor, 
                          textColor: textColor,
                          textSize: textSize,
                          
                          }

      var userID = document.querySelector("body").dataset.userId;
      fetch(`/users/${userID}/save-custom-article-theme`, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
        body: JSON.stringify(
          {
            customThemeStyles: JSON.stringify(customStyles)
          }
        )
      }).then(response => {
        if (response.ok) {
          document.querySelector(".article-theme-customizer").classList.add("is-hidden")
        }
      })

    }

    resetCustomStyles(removeDarkTheme=true) {
      document.querySelectorAll(".article-content-formatted, #article_card_content p").forEach(el => el.style.fontSize = "")
      document.querySelectorAll(".article-content-formatted, .article-content-formatted p, .article-content-formatted span, #article_card_content h1").forEach(el => el.style.color = "")
      document.querySelector("#article_card_content").style.backgroundColor = ""
      if (removeDarkTheme) {
        document.querySelector("#article_card_content").classList.remove("dark-theme")
      }
      document.querySelectorAll(".article-theme-customizer .pcr-button").forEach(el => el.style = "")
      this.currentTextSizeIndicatorTarget.innerHTML = "1"

      if (removeDarkTheme) this.saveCustomStyles()
    }

    applyCustomStyles() {
      var customArticleStyles = JSON.parse(document.querySelector("body").dataset.customArticleThemeStyles)
      var darkTheme = customArticleStyles["darkTheme"] == undefined ? "" : customArticleStyles["darkTheme"]
      var backgroundColor = customArticleStyles["backgroundColor"] == undefined ? "" : customArticleStyles["backgroundColor"]
      var textColor = customArticleStyles["textColor"] == undefined ? "" : customArticleStyles["textColor"]
      var textSize = customArticleStyles["textSize"] == undefined ? "" : customArticleStyles["textSize"]
      this.currentTextSizeIndicatorTarget.innerHTML = this.currentTextSize(textSize);

      if (darkTheme) document.querySelector("#article_card_content").classList.add("dark-theme")
      document.querySelector("#article_card_content").style.backgroundColor = backgroundColor
      
      if (!!textColor) {
        document.querySelectorAll(".article-content-formatted, .article-content-formatted p, .article-content-formatted span, #article_card_content h1").forEach(el => el.style.color = textColor)
      }

      document.querySelectorAll(".article-content-formatted, #article_card_content p").forEach(el => el.style.fontSize = textSize)

    }
    

}