import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["input", "counter"];

  connect() {
    this.updateCounter();
  }

  updateCounter() {
    var maxCharacters = parseInt(this.element.dataset.maxCharacters);
    var textLength = this.inputTarget.value.length;
    this.counterTarget.innerText = textLength;
    if (textLength > maxCharacters) {
      this.counterTarget.classList.add("text-red-600")
    } else {
      this.counterTarget.classList.remove("text-red-600")
    }
  }


}