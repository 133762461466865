import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
// import Dragula from 'dragula'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["list"]

  connect () {

    

    this.sortable = Sortable.create(this.element, {
      group: "sections",
      draggable: ".course-section-column",
      handle: ".course-section-handle",

      onMove: function(e) {
        console.log("moving section", e);
      },
      onEnd: function(evt) {
        console.log("END");
      
        let itemEl = evt.item;
        let itemNewIndex = evt.newIndex;

        console.log(evt);
 
        if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return
        

        var params = {
          
          position: itemNewIndex+1,
        }

         fetch(itemEl.dataset.sortableUpdateUrl, {
          method: 'PATCH',
          credentials: 'same-origin',
          headers: {
            'Accept': 'text/vnd.turbo-stream.html',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
            body: JSON.stringify(params)
          }).then(response => {
            if (response.ok) {
            }
          })


      }
    })
  }


  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

}