// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import { Notifier } from '@airbrake/browser';
import "chartkick/chart.js";
import "flag-icons/css/flag-icons.min.css";
// import { Idiomorph } from "idiomorph";
import { getFingerprint, getFingerprintData, setOption, includeComponent } from '@thumbmarkjs/thumbmarkjs'


import DisableDevtool from 'disable-devtool';




Rails.start();
ActiveStorage.start();

DisableDevtool({
  disableMenu: false,
  url: "http://--",
  md5: "fa51f6b5b7f27f3447cc16d76d7e6a3b",
  tkName: "edtcon",
});

setOption('logging', false);
setOption('exclude', ['permissions']);


function getScreenResolution() {

  return new Promise((resolve, reject) => {
    const width = window.screen.width * window.devicePixelRatio;
    const height = window.screen.height * window.devicePixelRatio;
    
    resolve(`${width}x${height}`);
  })


}

function getAvailableResolution() {

  return new Promise((resolve, reject) => {    
    resolve(`${window.screen.availWidth}x${window.screen.availHeight}`);
  })

}



includeComponent("availableResolution", getAvailableResolution);
includeComponent("screenResolution", getScreenResolution);

getFingerprint().then(

  function (fp) {

    getFingerprintData().then(
      function (fpData) {
        
        var userID = document.body.dataset.userId || "guest";
        var hashes = localStorage.getItem(`${userID}_spambotAnalyserHashes`);


        if (!!hashes) {
          hashes = JSON.parse(hashes);
          if (hashes.includes(fp)) return;
        }
        
        if (document.body.classList.contains("imk")) return;

        fetch("/spambot_analyser", {
          method: "post",
          credentials: "same-origin",
          headers: {
            'Accept': "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          body: JSON.stringify({ hash: fp, raw_fp_data: JSON.stringify(fpData), user_id: document.body.dataset.userId}),
        }).then((response) => {
          if (response.ok) {
            localStorage.setItem(`${userID}_spambotAnalyserHashes`, JSON.stringify([fp]));
          }
        });

      }

    )

  }


);




import "controllers"
import "stylesheets/application"
// import TimeMe from "timeme.js"
import "autolink"

import "track"
import 'animate.css'
// import "flexmasonry/src/flexmasonry.css"

// const airbrake = new Notifier({
//   projectId: 1,
//   projectKey: 'ff52cbb2743e25122f8bd8a23fd2600d',
//   host: 'https://bugtracker.acd.academy',
//   remoteConfig: false,
//   keysBlocklist: [
//     'password',
//     /secret/,
//   ]
// });

// airbrake.addFilter((notice) => {
//   notice.context.environment = process.env.RAILS_ENV;
//   return notice;
// });


// window.onerror = function(error, url, lineNumber) {
//   airbrake.notify(JSON.stringify(error, url, lineNumber));
//   return true;
// };

window.copySupportEmailAddress = function (ev) {
  navigator.clipboard.writeText("info@accademiadicoscienzadimensionale.it");
  document.querySelectorAll(".copied-email-tooltip").forEach((e) => {
    e.classList.remove("hidden");

    setTimeout(() => {
      e.classList.add("hidden");
    }, 2500);
  });
}

// TODO check page
// document.querySelector(".body.reviews").addEventListener("turbo:before-render", (event) => {
//   event.detail.render = async (prevEl, newEl) => {
//     await new Promise((resolve) => setTimeout(() => resolve(), 0));
//     Idiomorph.morph(prevEl, newEl);
//   };
// });

document.addEventListener("turbo:load", (event) => {
  // import("goodshare.js");
})
