import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {

  
  connect() {
    
     this.players = Plyr.setup(".acd-media-player", {
      storage: false,
      autopause: true,
      disableContextMenu: true,
      controls: [
        "play-large", // The large play button in the center
        "restart", // Restart playback
        "rewind", // Rewind by the seek time (default 10 seconds)
        "play", // Play/pause playback
        "fast-forward", // Fast forward by the seek time (default 10 seconds)
        "progress", // The progress bar and scrubber for playback and buffering
        "current-time", // The current time of playback
        "duration", // The full duration of the media
        "mute", // Toggle mute
        "volume", // Volume control
        "settings", // Settings menu
        "fullscreen", // Toggle fullscreen
      ],
      i18n: {
        restart: "Riavvia",
        rewind: "Indietro di {seektime}s",
        play: "Play",
        pause: "Pausa",
        fastForward: "Avanti di {seektime}s",
        seek: "Seek",
        seekLabel: "{currentTime} of {duration}",
        played: "Riprodotto",
        buffered: "Buffered",
        currentTime: "Minuto attuale",
        duration: "Durata",
        volume: "Volume",
        mute: "Silenzia",
        unmute: "Attiva audio",
        enableCaptions: "Attiva sottotitoli",
        disableCaptions: "Disattiva sottotitoli",
        download: "Download",
        enterFullscreen: "Guarda in schermo intero",
        exitFullscreen: "Esci da schermo intero",
        frameTitle: "ACD Player - {title}",
        captions: "Sottotitoli",
        settings: "Impostazioni",
        pip: "PIP",
        menuBack: "Vai al menu precedente",
        speed: "Velocità",
        normal: "Normale",
        quality: "Qualità",
        loop: "Loop",
        start: "Inizio",
        end: "Fine",
        all: "Tutto",
        reset: "Reset",
        disabled: "Disabilitato",
        enabled: "Abilitato",
        qualityBadge: {
          2160: "4K",
          1440: "HD",
          1080: "HD",
          720: "HD",
          576: "SD",
          480: "SD",
        },
      },
    });

    this.players.forEach((player, i) => {

      player.on("ended", event => {
        
        // const instance = event.detail.plyr;

        // var playerN = instance.elements.container.parentElement.parentElement.dataset.playerN;

        if (!!this.players[i+1]) {
          player.stop();
          this.players[i+1].play();
        }


      })

    })
  }

  start() {
    this.players[0].play()
  }

}