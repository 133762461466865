import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btn", "modal", "closeModalBtn", "quickLinksFrame"];

  connect() {

    document.addEventListener("click", function(e) {

      if (!!document.getElementById("quickLinkEditForm")) {
        if (!e.target.closest("#quickLinkEditForm")) {
          document.getElementById("quickLinkEditForm").parentElement.src = "";
          document.getElementById("quickLinkEditForm").parentElement.innerHTML = "";
          
          // document.getElementById("quickLinkEditForm").closest("turbo-frame").innerHTML = "";
        
        }
      }
    
    })
    
    document.addEventListener("click", function(e) {

     
        if (!e.target.closest("#quickLinksModal") && !e.target.closest("#toggleQuickLinksBtn") && !e.target.closest(".navbar-btn")) {
          if (!!document.getElementById("quickLinksModal")) {
            document.getElementById("quickLinksModal").classList.add("translate-x-full");
          }
        }
     

     


    });

    document.querySelectorAll(".create-quick-link-btn").forEach((btn) => {
      btn.addEventListener("click", (e) => {
        btn.classList.remove("opacity-40")
        btn.style.filter = "hue-rotate(60deg)"
        btn.disabed = true
      });
    });


    if (document.querySelectorAll('.create-quick-link-btn').length) {
      document.querySelector("#toggleQuickLinks").classList.remove("hidden");
    }


    if (!!document.querySelector("#toggleQuickLinks")) {
      document.querySelector("#toggleQuickLinks").addEventListener("click", (e) => {
        document.querySelectorAll(".create-quick-link-btn").forEach((el) => {
          el.classList.remove("hidden");
        }
      );
      })
    }
    
    
    
    if (this.hasBtnTarget) {
      document.addEventListener("mousemove", (e) => {
        if (e.clientX > (window.innerWidth/100*75)) {
          if (this.btnTarget.classList.contains("translate-x-40")) {
            this.btnTarget.classList.remove("translate-x-40");
          }
        } else {
          if (!this.btnTarget.classList.contains("translate-x-40")) {
            this.btnTarget.classList.add("translate-x-40");
          }
        }
        
      });
    }



    if (!!document.getElementById("quickLinksModal")) {
      document.getElementById("quickLinksModal").addEventListener("click", (e) => {
        var modal = document.getElementById("quickLinksModal");
  
        if (modal.classList.contains("translate-x-full")) {
          modal.classList.remove("translate-x-full")
        } else {
          if (e.target == modal || (this.hasCloseModalBtnTarget && e.target == this.closeModalBtnTarget) ) {
            e.stopPropagation();
            modal.classList.add("translate-x-full");
          }
        }
      }); 
    }

  }

  toggleModal(e) {
    
    var modal = document.getElementById("quickLinksModal");
    
    
    modal.classList.toggle("translate-x-full");
    
    
    // force reload quick_links turbo-frame
    if (!modal.classList.contains("translate-x-full")) {
      modal.querySelector("turbo-frame#quick_links").reload();
      console.log("reloading");
    }
    






  }

}