import { Controller } from "stimulus";
import Rails from 'rails-ujs'

export default class extends Controller {

  static targets = ["articleCard", 
                    "decrementTextSizeButton", 
                    "incrementTextSizeButton", 
                    "currentTextSizeIndicator", 
                    "toggleThemeCustomizerButton",
                    "themeCustomizer"]

    initialize() {
      this.applyCustomStyles()
      this.articleId = document.querySelector("#article-card").dataset.articleId;
    }

    toggleDarkTheme() {
      this.resetCustomStyles(false)
      // this.articleCardTarget.querySelector("#article_card_content").classList.toggle('dark-theme')
      document.querySelector(".comments-list-footer").classList.toggle("dark-theme")
    }

    currentTextSize(textSize) {
        var currentTextSizeIndicator;

        switch (textSize) {
        case "":
          currentTextSizeIndicator = "1"
          break;
        case "1.4rem":
          currentTextSizeIndicator = "5"
          break;
        case "1.3rem":
          currentTextSizeIndicator = "4"
          break;
        case "1.2rem":
          currentTextSizeIndicator = "3"
          break;
        case "1.1rem":
          currentTextSizeIndicator = "2"
          break;
        default:
          currentTextSizeIndicator = "1"
          break;
      }

      return currentTextSizeIndicator;
    }

    decrementTextSize() {
      var currentTextSize = document.querySelector(".comment").style.fontSize;
      var nextTextSize;
      var currentTextSizeIndicator;

      switch (currentTextSize) {
        case "":
          nextTextSize = "1rem"
          currentTextSizeIndicator = "1"
          break;
        case "1.4rem":
          nextTextSize = "1.3rem"
          currentTextSizeIndicator = "4"
          break;
        case "1.3rem":
          nextTextSize = "1.2rem"
          currentTextSizeIndicator = "3"
          break;
        case "1.2rem":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1.1rem":
          nextTextSize = "1rem"
          currentTextSizeIndicator = "1"
          break;
        default:
          break;
      }

      document.querySelectorAll(".comment").forEach(el => el.style.fontSize = nextTextSize)
      if (currentTextSizeIndicator != undefined) this.currentTextSizeIndicatorTarget.innerHTML = currentTextSizeIndicator
    }

    incrementTextSize() {
      
      var currentTextSize = document.querySelector(".comment").style.fontSize;

      var nextTextSize;
      var currentTextSizeIndicator;

      switch (currentTextSize) {
        case "":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1rem":
          nextTextSize = "1.1rem"
          currentTextSizeIndicator = "2"
          break;
        case "1.1rem":
          nextTextSize = "1.2rem"
          currentTextSizeIndicator = "3"
          break;
        case "1.2rem":
          nextTextSize = "1.3rem"
          currentTextSizeIndicator = "4"
          break;
        case "1.3rem":
          nextTextSize = "1.4rem"
          currentTextSizeIndicator = "5"
          break;
        default:
          break;
      }

      document.querySelectorAll(".comment").forEach(el => el.style.fontSize = nextTextSize)
      if (currentTextSizeIndicator != undefined) this.currentTextSizeIndicatorTarget.innerHTML = currentTextSizeIndicator
    }

    toggleThemeCustomizer() {

      
      
      if (this.themeCustomizerTarget.classList.contains("is-hidden")) {
        this.themeCustomizerTarget.classList.remove("is-hidden")
      } else {
        this.themeCustomizerTarget.classList.add("is-hidden")
      }
    }

    saveCustomStyles() {

      var commentsDarkTheme = document.querySelector(".comments-list-footer").classList.contains("dark-theme")
      
      
      
      var commentsBackgroundColor = document.querySelector(".comment").style.background;
      var commentsTextColor = document.querySelector(".comment").style.color;
      var commentsTextSize = document.querySelector(".comment").style.fontSize

      var customStyles = {commentsDarkTheme: commentsDarkTheme, 
                          commentsTextSize: commentsTextSize,
                          commentsBackgroundColor: commentsBackgroundColor,
                          commentsTextColor: commentsTextColor,
                          }

      var userID = document.querySelector("body").dataset.userId;
      fetch(`/users/${userID}/save-custom-comments-theme`, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
        body: JSON.stringify(
          {
            customThemeStyles: JSON.stringify(customStyles)
          }
        )
      }).then(response => {
        if (response.ok) {
          document.querySelector(".comments-theme-customizer").classList.add("is-hidden")
          document.querySelector("#articleBody").dataset.customCommentsThemeStyles = JSON.stringify(customStyles);
        }
      })

    }

    resetCustomStyles(removeDarkTheme=true) {
      document.querySelectorAll(".comment").forEach(el => el.style.fontSize = "")
      document.querySelectorAll(".comment").forEach(el => el.style.color = "")
      document.querySelectorAll(".comment").forEach(comment => comment.style.background = "#a6c9ff")
      if (removeDarkTheme) {
        document.querySelector(".comments-list-footer").classList.remove("dark-theme")
      }
      document.querySelectorAll(".comments-theme-customizer .pcr-button").forEach(el => el.style = "")
      this.currentTextSizeIndicatorTarget.innerHTML = "1"

      if (removeDarkTheme) this.saveCustomStyles()
    }

    applyCustomStyles() {

      var customArticleStyles = JSON.parse(document.querySelector("#articleBody").dataset.customCommentsThemeStyles)
      

      var commentsDarkTheme = customArticleStyles["commentsDarkTheme"] == undefined ? "" : customArticleStyles["commentsDarkTheme"]
      var backgroundColor = customArticleStyles["commentsBackgroundColor"] == undefined ? "#a6c9ff" : customArticleStyles["commentsBackgroundColor"]
      var textColor = customArticleStyles["commentsTextColor"] == undefined ? "" : customArticleStyles["commentsTextColor"]
      var textSize = customArticleStyles["commentsTextSize"] == undefined ? "" : customArticleStyles["commentsTextSize"]
      this.currentTextSizeIndicatorTarget.innerHTML = this.currentTextSize(textSize);

      if (commentsDarkTheme) document.querySelector(".comments-list-footer").classList.add("dark-theme")
      document.querySelectorAll(".comment").forEach(comment => comment.style.background = backgroundColor);
      
      if (!!textColor) {
        document.querySelectorAll(".comment").forEach(el => el.style.color = textColor)
      }

      document.querySelectorAll(".comment").forEach(el => el.style.fontSize = textSize)

    }
    

}