import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails, { start } from '@rails/ujs'


export default class extends Controller {
  static targets = ["list", "toggleSortableBtn"]


  connect() {
    var prevItems = Number(document.querySelector(".review-item").dataset.position);
    var startingIndexOffset = prevItems-1;
    

    this.sortable = Sortable.create(this.element, {
      group: "reviews",
      animation: 300,
      ghostClass: "bg-purple-100",
      disabled: true,
     
      
    
      onMove: function(evt) {
        console.log("move")
      },
      onEnd: function(evt) {
        let itemEl = evt.item;
        let itemNewIndex = evt.newIndex;
        if (!itemEl.dataset.sortableUpdateUrl || !window._rails_loaded) return


        var position = itemNewIndex;
        
        var pageParam = new URLSearchParams(document.location.search).get("page");
        if (!!pageParam) {
          console.log("new offset", startingIndexOffset);
          position = position+startingIndexOffset;
          console.log("new position", position);
        }

     

        var params = {
          review: {
            position: position,
          },
        }

        fetch(itemEl.dataset.sortableUpdateUrl, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken(),
        },
          body: JSON.stringify(params)
        }).then(response => {
          if (response.ok) {
            console.log("position: updated")
          }
        })


      }

    })
  }

  toggleSortable() {
    var state = this.sortable.option("disabled")
    this.sortable.option("disabled", !state);
    console.log("toggle", state);
    this.toggleSortableBtnTarget.classList.toggle("bg-green-400");
  }


  closeGallery(e) {
    console.log("a");
    document.getElementById("photoGallery").classList.add("hidden");
  }


  zoomPhoto(e) {
    
    var imageUrl = e.target.src;
    var gallery = document.getElementById("photoGallery");
    var galleryImage = document.getElementById("photoGalleryImage");
    gallery.classList.remove("hidden");
    console.log(galleryImage.style.backgroundImage);
    galleryImage.style.backgroundImage = `url("${imageUrl}")`;
  }




  // disconnect () {
  //   this.sortable.destroy()
  //   this.sortable = undefined
  // }

}